import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { IpAddressResourceType } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { showModal } from "../../components/modal/registerModal.tsx";
import { getResourceTypeName } from "../../modules/api/resourceTypes.tsx";
import { ipAddressResourceTypeToType } from "../../modules/network/ipAddress/resourceId.ts";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { ActionButtons, type ActionItem } from "../ActionItem.tsx";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { Extra, YesNo } from "../AdminTable.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { BooleanComponentFilter, TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { IpAddressOrderFields, OrderDirection, type GetIpAddressListQuery } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { adminLoadBalancerLink, adminServicePackageLink, adminVmLink } from "../itemLinks.ts";
import { useCursorPager } from "../Pager.tsx";
import { baIdField, locationField, userIdField } from "../table_fields/standardFields.tsx";
import { DeleteFloatingIpModalContent } from "./DeleteFloatingIpBlock.tsx";
import { unassignAction } from "./unassignAction.tsx";

const GET_IP_ADDRESS_LIST = gql(/* GraphQL */ `
    query GetIpAddressList(
        $limit: Int
        $orderField: IpAddressOrderFields
        $orderDir: OrderDirection
        $cursor: String
        $page: Int
        $locations: [String!]
        $search: String
        $filters: [IpAddressFilter!]
    ) {
        admin_ip_address_list(
            limit: $limit
            orderField: $orderField
            orderDir: $orderDir
            cursor: $cursor
            page: $page
            locations: $locations
            search: $search
            filters: $filters
        ) {
            items {
                uuid
                user_id
                billing_account_id
                address
                is_ipv6
                name
                created_at
                updated_at
                assigned_to
                assigned_to_resource_type
                unassigned_at
                _location
                billing_account_object {
                    ...BillingAccountBlock
                }
            }
            paging {
                total
                cursor
            }
        }
    }
`);

export type GQIpAddressItem = NonNullable<GetIpAddressListQuery["admin_ip_address_list"]["items"]>[number];

function AssignedToItem({ item }: { item: GQIpAddressItem }) {
    const { assigned_to, assigned_to_resource_type } = item;

    if (!assigned_to) {
        return <span className="text-muted">Unassigned</span>;
    }

    const resourceType = ipAddressResourceTypeToType(assigned_to_resource_type as IpAddressResourceType);
    const link = (() => {
        switch (resourceType) {
            case "managed_service":
                return adminServicePackageLink(assigned_to);
            case "virtual_machine":
                return adminVmLink(assigned_to);
            case "load_balancer":
                return adminLoadBalancerLink(assigned_to);
        }
    })();

    return (
        <AdminLinkButton
            action={link}
            label={<Extra value={getResourceTypeName(resourceType)}>{item.assigned_to}</Extra>}
        />
    );
}

const ipAddressFields: GqlFieldsOf<GQIpAddressItem, IpAddressOrderFields> = [
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
        order: IpAddressOrderFields.Uuid,
    }),
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: IpAddressOrderFields.UserId,
    }),
    gf({
        ...baIdField,
        get: (a) => a.billing_account_id,
        order: IpAddressOrderFields.BillingAccountId,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account_object,
        render: BillingAccountBlock,
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => (a.created_at ? apiUnixToDate(a.created_at as number) : undefined),
        order: IpAddressOrderFields.CreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "address",
        title: "Address",
        get: (a) => a.address,
        copyable: true,
        order: IpAddressOrderFields.Address,
        filter: TextFilter,
    }),
    gf({
        id: "is_ipv6",
        title: "Is IPv6",
        get: (a) => a.is_ipv6,
        render: YesNo,
        order: IpAddressOrderFields.IsIpv6,
        filter: () => <BooleanComponentFilter component={YesNo} />,
    }),
    gf({
        id: "name",
        title: "Name",
        get: (a) => a.name,
        order: IpAddressOrderFields.Name,
        filter: TextFilter,
    }),
    gf({
        id: "assigned_to",
        title: "Assigned To",
        get: (a) => a.assigned_to,
        render: AssignedToItem,
    }),
    gf({
        id: "unassigned_at",
        title: "Unassigned At",
        get: (a) => (a.unassigned_at ? apiUnixToDate(a.unassigned_at as number) : undefined),
        order: IpAddressOrderFields.UnassignedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        ...locationField,
        get: (a) => a._location,
    }),
];

function actions(item: GQIpAddressItem): ActionItem[] {
    return [
        unassignAction(
            !item.is_ipv6 && item.assigned_to
                ? {
                      address: item.address,
                      location: notNull(item._location),
                  }
                : null,
        ),
        {
            id: "delete_ip",
            title: "Delete",
            icon: "jp-trash-icon",
            isDisabled: item.is_ipv6,
            action: () => showModal(<DeleteFloatingIpModalContent item={item} />),
        },
    ];
}

export function FloatingIpsTable() {
    return (
        <GraphqlTable
            title="Floating IPs"
            fields={ipAddressFields}
            defaults={{ orderField: IpAddressOrderFields.CreatedAt, orderDir: OrderDirection.Desc }}
            getId={(item) => item.uuid!}
            usePager={useCursorPager}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_IP_ADDRESS_LIST, { variables });
                return extractData(q, (d) => d.admin_ip_address_list);
            }}
            actionButtons={(item) => <ActionButtons actions={actions(item)} inTable />}
        />
    );
}
